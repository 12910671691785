// Import dependencies
import React from "react";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import { PageContainer } from "../styled-components/UILibrary";
import HeaderSeparator from "../components/separators/headerSeparator";
import SectionSeparator from "../components/separators/sectionSeparator";
import TitleSection from "../components/sections/titleSection";
import GeneralButton from "../components/form/buttons/GeneralButton";
import ErrorIcon from "../assets/icons/error.svg";
import SearchBar from "../components/search-bar/searchBar";

// Import styles
import "./404.css";

/*
    Error during memebership purchase
*/
const Error = () => (
  <Layout center>
    <SEO title="Error"></SEO>

    <SearchBar/>

    <PageContainer style={{margin: "auto", textAlign: "center"}}>

    <div className="container_statu">
      <ErrorIcon/>
      <h1>Something went wrong...</h1>
    </div>

    </PageContainer>
  </Layout>
)

export default Error
